export const baseFigmaLink = 'https://www.figma.com/file/32bzXrc5Bj6IOGnrdxF7WL/youdeploy-Master-👑'

export const figmaLinks = {
  apiKeys: `${baseFigmaLink}?node-id=5639%3A0`,
  catalogRepositories: `${baseFigmaLink}?node-id=125%3A1762`,
  configRepositories: `${baseFigmaLink}?node-id=633%3A59983`,
  credentials: `${baseFigmaLink}?node-id=125%3A1755`,
  dashboard: `${baseFigmaLink}?node-id=125%3A1753`,
  events: `${baseFigmaLink}?node-id=125%3A1765`,
  infraImport: `${baseFigmaLink}?node-id=5436%3A139246`,
  infraPolicies: `${baseFigmaLink}?node-id=3055%3A115722`,
  inventory: `https://www.figma.com/file/vFhhWy5tVKgPo6vCy8Ohvn/Inventory?node-id=214%3A18854`,
  login: `${baseFigmaLink}?node-id=2%3A57`,
  members: `${baseFigmaLink}?node-id=3876%3A121680`,
  organizations: `${baseFigmaLink}?node-id=66%3A1724`,
  pendingInvites: `${baseFigmaLink}?node-id=7344%3A149540`,
  pipelinesOverview: `${baseFigmaLink}?node-id=125%3A1756`,
  profile: `${baseFigmaLink}?node-id=125%3A1767`,
  projects: `${baseFigmaLink}?node-id=125%3A1754`,
  roles: `${baseFigmaLink}?node-id=125%3A1766`,
  stack: 'https://www.figma.com/file/yC2CTEcYp4jdpFLumIpqYp/Stacks-%3E-Stack-Cards',
  stacks: `${baseFigmaLink}?node-id=125%3A1752`,
  status: `${baseFigmaLink}?node-id=3370%3A1368`,
  teams: `${baseFigmaLink}?node-id=125%3A1764`,
}

export function getFigmaLink (key) {
  return figmaLinks[key] || baseFigmaLink
}
