<template>
  <ul class="secondary-nav space-x-2 pl-2">
    <li class="secondary-nav__item">
      <CySecondaryNavHelpMenu/>
    </li>
    <li
      v-if="$showFeature.notifications"
      class="secondary-nav__item">
      <v-badge
        color="error"
        :offset-x="14"
        :offset-y="14"
        bordered
        overlap
        dot>
        <CyButton
          aria-label="Notifications"
          icon="notifications"
          icon-only
          theme="primary"
          variant="tertiary"
          sm
          :to="{ name: 'notifications' }"/>
      </v-badge>
    </li>
    <li class="secondary-nav__item">
      <CySecondaryNavUserMenu/>
    </li>
  </ul>
</template>

<script>
import CySecondaryNavHelpMenu from '@/components/secondary-nav/help-menu.vue'
import CySecondaryNavUserMenu from '@/components/secondary-nav/user-menu.vue'

export default {
  name: 'CySecondaryNav',
  components: {
    CySecondaryNavHelpMenu,
    CySecondaryNavUserMenu,
  },
}
</script>

<style lang="scss" scoped>
$nav-item-size: 32px;

.secondary-nav {
  display: flex;
  align-items: center;
  list-style: none;

  ::v-deep .v-badge {
    &__badge {
      &::after {
        border-width: 2px;
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    height: $nav-item-size;
  }
}
</style>
