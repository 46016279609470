var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CyMenu',{attrs:{"bottom":"","left":"","offset-y":"","transition":"slide-y-transition","origin":"top right","content-class":"mt-2 py-2 cy-menu secondary-nav__menu cy-menu-help help-menu no-active-indicators","nudge-bottom":"10","nudge-right":"10","role":"help-menu"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',[_c('CyButton',_vm._g({attrs:{"variant":"tertiary","theme":"primary","icon":"help_outline","icon-only":"","sm":""},on:{"click":function($event){return _vm.$gtm.trackEvent(_vm.$static.gtmHeaderEvents.headNewReleasesHelpDropDown)}}},on))],1),(_vm.servicesStatus.status === 'Error')?_c('span',{staticClass:"cy-menu__red-dot"}):_vm._e()]}}])},[_c('v-list',{staticClass:"py-0",attrs:{"dense":""}},[_vm._l((_vm.helpItems),function(ref){
var key = ref.key;
var icon = ref.icon;
var text = ref.text;
var action = ref.action; if ( action === void 0 ) action = function () {};
var to = ref.to;
var cssClass = ref.cssClass; if ( cssClass === void 0 ) cssClass = '';
var color = ref.color;
return [(key !== 'divider')?_c('v-list-item',{directives:[{name:"hover-siblings",rawName:"v-hover-siblings"}],key:(key + "-" + color + "-listItem"),class:[cssClass, 'cy-menu__item'],attrs:{"to":to},on:{"click":action}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":color || 'primary',"size":"22"}},[_vm._v(" "+_vm._s(icon)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{class:{
              'v-list-item__title--error': key === 'status' && _vm.servicesStatus.status === 'Error',
            }},[_vm._v(" "+_vm._s(text)+" ")])],1)],1):_c('v-divider',{key:(key + "-" + color + "-divider"),staticClass:"my-2"})]})],2),_c('div',{staticClass:"bottom-links",on:{"click":function($event){$event.stopPropagation();}}},[_c('span',[_vm._v(_vm._s(_vm.appVersion))]),_c('br'),(_vm.apiVersion)?_c('span',[_vm._v("API "+_vm._s(_vm.apiVersion.version))]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }