<template>
  <transition name="slide-fade">
    <CyPresentationBubble
      v-show="isStepVisible"
      class="product-tour__step"
      :heading="$t(`routes.${name}`)"
      :explanation="$t(`${name}`)"
      :current-step="displayIndex"
      :total-steps="totalStepCount">
      <template #actions>
        <CyButton
          v-if="!isFinalStep"
          theme="primary"
          variant="tertiary"
          sm
          @click="handleSkip()">
          {{ $t('skip') }}
        </CyButton>
        <CyButton
          variant="primary"
          sm
          @click="handleNext()">
          {{ nextButtonText }}
        </CyButton>
      </template>
    </CyPresentationBubble>
  </transition>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import CyPresentationBubble from '@/components/presentation-bubble.vue'

/**
 * Handles the state, positioning and content of individual CyPresentationBubble-s used in the product tour.
 *
 * @prop {Number}   [index]             Current step's position in the loop.
 * @prop {String}   [name]              Current step's name key. Used for referencing translations.
 * @prop {Number}   [totalStepCount]    Total number of steps in the tour. Used in the progress indicator, e.g. 1 / 5.
 *
 * @link Figma Product tour: https://www.figma.com/file/32bzXrc5Bj6IOGnrdxF7WL/youdeploy-Master-%F0%9F%91%91?node-id=5609%3A1
 */
export default {
  name: 'CyProductTourStep',
  components: {
    CyPresentationBubble,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    totalStepCount: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState({
      currentTourStep: (state) => state.user.guide.tour.currentStep,
      isTourCompleted: (state) => state.user.guide.tour.completed,
    }),
    ...mapGetters('organization/billing', [
      'hasActiveBillingPlan',
    ]),
    ...mapGetters('organization', [
      'isOrgSelected',
    ]),
    displayIndex () {
      return this.index + 1
    },
    isStepVisible () {
      return _.every([
        this.isOrgSelected,
        this.hasActiveBillingPlan,
        !this.isTourCompleted,
        this.index === this.currentTourStep,
      ])
    },
    isFinalStep () {
      return this.displayIndex === this.totalStepCount
    },
    nextButtonText () {
      return this.isFinalStep ? this.$t('finish') : this.$t('forms.next')
    },
  },
  methods: {
    ...mapMutations('user', [
      'SET_TOUR_STEP',
    ]),
    ...mapActions('user', [
      'COMPLETE_TOUR',
    ]),
    handleNext () {
      this.$gtm.trackEvent({
        event: 'productTourNextStep',
        step: this.displayIndex,
      })
      if (this.isFinalStep) {
        this.COMPLETE_TOUR()
      } else {
        this.SET_TOUR_STEP(this.index + 1)
      }
    },
    handleSkip () {
      this.$gtm.trackEvent({
        event: 'productTourSkip',
        step: this.displayIndex,
      })
      this.COMPLETE_TOUR()
    },
  },
  i18n: {
    messages: {
      en: {
        cloudCostManagementSection: 'Keep track on the impact your infrastructures have on finances',
        dashboard: 'All your important KPIs and shortcuts at a glance',
        finish: 'Finish the tour',
        resourcesSection: 'Control or restrict the resources your teams can use by creating memory, storage and CPU limits',
        observabilitySection: 'Keep an eye on the health and cost of your projects, stay aware of changes made accross your organization',
        projectsSection: 'Deploy, control, and manage your projects',
        securitySection: 'Ensure correct access is given to each person or entity in your organization',
        skip: 'skip tour',
        stacksSection: 'Centralize and organize all your infrastructure as code templates into modulable and reusable stacks',
      },
      es: {
        cloudCostManagementSection: 'Realice un seguimiento del impacto que sus infraestructuras tienen en las finanzas',
        dashboard: 'Todos sus KPI y atajos importantes de un vistazo',
        finish: 'Finalizar el tour',
        resourcesSection: 'Controle o restrinja los recursos que sus equipos pueden usar creando límites de memoria, almacenamiento y CPU',
        observabilitySection: 'Vigile el estado y el coste de sus proyectos, esté al tanto de los cambios realizados en su organización',
        projectsSection: 'Despliegue, controle y administre sus proyectos',
        securitySection: 'Garantice que se da el acceso adecuado a cada persona o entidad en su organización',
        skip: 'omitir el tour',
        stacksSection: 'Centralice y organice toda su infraestructura como código en stacks modulares y reutilizables',
      },
      fr: {
        cloudCostManagementSection: `Suivez l'impact de vos infrastructures sur vos finances`,
        dashboard: `Tous vos KPI et vos raccourcis importants en un coup d'œil`,
        finish: 'Terminer le présentation',
        resourcesSection: 'Contrôlez ou restreignez les ressources que vos équipes peuvent utiliser en créant des limites de mémoire, de stockage et de CPU',
        observabilitySection: 'Gardez un œil sur la santé et le coût de vos projets, restez au courant des changements apportés au sein de votre organisation',
        projectsSection: 'Déployez, contrôlez et gérez vos projets',
        securitySection: 'Assurez que chaque personne ou entité dans votre organisation dispose des bons accès',
        skip: 'passer le présentation',
        stacksSection: 'Centralisez et organisez toute votre infrastructure sous forme de modèles de code dans des stacks modulables et réutilisables',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.product-tour__step {
  position: fixed;
  left: 100%;
  margin-top: -44px;
}

.slide-fade {
  &-leave-active,
  &-enter-active {
    transform-origin: right center;
    transition: 0.4s ease;
  }

  &-enter {
    transform: translateX(100%);
    opacity: 0;
  }

  &-leave-to {
    transform: translateY(-100%);
    opacity: 0;
  }
}
</style>
