<template>
  <ul
    v-if="isOrgSettingsPage"
    class="breadcrumb pl-0">
    <li class="breadcrumb__item breadcrumb__item--parent">
      <button
        class="breadcrumb__link"
        data-cy="organizations-item"
        @click="goToOrganizationsList">
        {{ $t('Organizations') }}
      </button>
      <span class="breadcrumb__separator">
        /
      </span>
    </li>
    <li class="breadcrumb__item breadcrumb__item--parent">
      <button
        class="breadcrumb__link"
        data-cy="org-name-item"
        @click="goToOrganizationSettings">
        {{ currentOrganization.name }}
      </button>
      <span class="breadcrumb__separator">
        /
      </span>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex'
import { gtmBreadcrumbsEvents } from '@/utils/helpers/analytics'

export default {
  name: 'CyToolbarOrgSettingsBreadcrumbs',
  computed: {
    ...mapGetters('organization', {
      currentOrganization: 'organization',
    }),
    $static: () => ({
      gtmBreadcrumbsEvents,
      organizationTabs: [
        'settings',
        'workers',
        'members',
        'teams',
      ],
    }),
    isOrgSettingsPage () {
      const { fullPath } = this.$route
      const matches = _.intersection(this.$static.organizationTabs, fullPath.split('/'))

      return matches.length > 0
    },
  },
  methods: {
    goToOrganizationsList () {
      this.$gtm.trackEvent(this.$static.gtmBreadcrumbsEvents.breadcrumbsOrganizationsSeeAllOrganizations)
      this.$router.push({ name: 'organizations' }).catch(() => { /* silenced */ })
    },
    goToOrganizationSettings () {
      const params = { orgCanonical: this.currentOrganization.canonical }
      this.$router.push({ name: 'orgSettings', params }).catch(() => { /* silenced */ })
    },
  },
}
</script>
