var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-breadcrumbs',{staticClass:"breadcrumb pl-0",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var to = ref.to; if ( to === void 0 ) to = {};
var label = ref.label;
var utils = ref.utils; if ( utils === void 0 ) utils = {};
return [_c('span',{staticClass:"breadcrumb__item"},[(to.name !== 'dashboard' && label !== '...')?[_c('router-link',{staticClass:"breadcrumb__link",attrs:{"to":to,"exact":""}},[_c('CyTooltip',{attrs:{"disabled":!_vm.ellipsisStatus[label],"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('span',_vm._g({ref:("breadcrumb-item-" + label),staticClass:"d-block breadcrumb__link",attrs:{"data-cy":"breadcrumb-item"},on:{"mouseover":function($event){return _vm.setEllipsisStatus(label)}}},onTooltip),[_vm._v(" "+_vm._s(label)+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(label)+" ")])],1),_c('span',{staticClass:"breadcrumb__separator"},[_vm._v(" / ")])]:_vm._e(),(utils.dropdownItems)?[_c('CyMenu',{attrs:{"min-width":"280","bottom":"","right":"","nudge-bottom":"4","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"breadcrumb__expand",attrs:{"label":"","small":""}},on),[_c('v-icon',[_vm._v(" more_horiz ")])],1)]}}],null,true)},[_c('v-list',{staticClass:"breadcrumb__menu",attrs:{"dense":""}},_vm._l((utils.dropdownItems),function(breadcrumb){return _c('v-list-item',{key:breadcrumb.label,staticClass:"breadcrumb__link",attrs:{"to":breadcrumb.to,"exact":""}},[_c('v-list-item-content',[_vm._v(" "+_vm._s(breadcrumb.label)+" ")])],1)}),1)],1),_c('span',{staticClass:"breadcrumb__separator"},[_vm._v(" / ")])]:_vm._e()],2)]}},{key:"current",fn:function(ref){
var label = ref.label;
return [_c('CyTooltip',{attrs:{"disabled":!_vm.ellipsisStatus[label],"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('span',_vm._g({ref:("breadcrumb-item-" + label),staticClass:"d-block breadcrumb__link breadcrumb__link--current",attrs:{"data-cy":"breadcrumb-current"},on:{"mouseover":function($event){return _vm.setEllipsisStatus(label)}}},onTooltip),[_vm._v(" "+_vm._s(label)+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(label)+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }