<template>
  <div class="v-timeline--left v-timeline--filled v-timeline--align-top">
    <div class="v-timeline-item">
      <div class="v-timeline-item__body">
        <v-card
          rounded="lg"
          light>
          <div class="card-severity"/>
          <v-card-title class="pb-1 pl-6 justify-space-between">
            <h2 class="h5 primary--text">
              {{ heading }}
            </h2>
            <div class="progress-indicator font-weight-regular">
              {{ currentStep }} / {{ totalSteps }}
            </div>
          </v-card-title>
          <v-card-text
            class="pl-6 events-item__text">
            {{ explanation }}
          </v-card-text>
          <v-card-actions class="pt-0 pr-4 pb-4 pl-6 justify-end">
            <slot name="actions"/>
          </v-card-actions>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * A reusable presentation bubble. Meant to be dumb and stateless.
 *
 * @prop {String}   [heading]
 * @prop {String}   [explanation]
 * @prop {Number}   [currentStep]
 * @prop {Number}   [totalSteps]
 *
 * @slot actions    Used for controls at the bottom of the bubble.
 *                  They should be defined in context so that this component stays stateless.
 *
 * @link Figma Presentation bubble component: https://www.figma.com/file/32bzXrc5Bj6IOGnrdxF7WL/youdeploy-Master-%F0%9F%91%91?node-id=5609%3A2051
 */
export default {
  name: 'CyPresentationBubble',

  props: {
    heading: {
      type: String,
      required: true,
    },

    explanation: {
      type: String,
      required: true,
    },

    currentStep: {
      type: Number,
      required: true,
    },

    totalSteps: {
      type: Number,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
  $accent: map.get($orange, "light-2");

  .card-severity {
    position: absolute;
    width: 8px;
    height: 100%;
    background-color: $accent;

    // needed to win specificity over Vuetify
    .v-timeline-item .v-timeline-item__body .v-card & {
      border-top-left-radius: 8px;
      border-top-right-radius: 0;
      border-bottom-left-radius: 8px;
    }
  }

  .v-timeline-item {
    flex-direction: row-reverse;
    padding-bottom: 0;
    padding-left: 10px;
  }

  .v-timeline-item__body {
    width: 254px;

    > .v-card:not(.v-card--flat) {
      &::before,
      &::after {
        top: 20px;
        left: -8px;
        border-top-width: 8px;
        border-right-width: 8px;
        border-bottom-width: 8px;
        border-right-color: transparent;
      }

      &::after {
        border-right-color: $accent;
      }
    }
  }

  .progress-indicator {
    color: map.get($slate-grey, "light-3");
    font-size: 10px;
    line-height: 150%;
  }
</style>
