<template>
  <app-breadcrumbs class="breadcrumb pl-0">
    <template #default="{ to = {}, label, utils = {} }">
      <span class="breadcrumb__item">
        <template v-if="to.name !== 'dashboard' && label !== '...'">
          <router-link
            class="breadcrumb__link"
            :to="to"
            exact>
            <CyTooltip
              :disabled="!ellipsisStatus[label]"
              bottom>
              <template #activator="{ on: onTooltip }">
                <span
                  :ref="`breadcrumb-item-${label}`"
                  data-cy="breadcrumb-item"
                  class="d-block breadcrumb__link"
                  v-on="onTooltip"
                  @mouseover="setEllipsisStatus(label)">
                  {{ label }}
                </span>
              </template>
              {{ label }}
            </CyTooltip>
          </router-link>
          <span
            class="breadcrumb__separator">
            /
          </span>
        </template>

        <template v-if="utils.dropdownItems">
          <CyMenu
            min-width="280"
            bottom
            right
            nudge-bottom="4"
            offset-y>
            <template #activator="{ on }">
              <v-chip
                class="breadcrumb__expand"
                label
                small
                v-on="on">
                <v-icon>
                  more_horiz
                </v-icon>
              </v-chip>
            </template>

            <v-list
              dense
              class="breadcrumb__menu">
              <v-list-item
                v-for="breadcrumb in utils.dropdownItems"
                :key="breadcrumb.label"
                :to="breadcrumb.to"
                class="breadcrumb__link"
                exact>
                <v-list-item-content>
                  {{ breadcrumb.label }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </CyMenu>
          <span
            class="breadcrumb__separator">
            /
          </span>
        </template>
      </span>
    </template>

    <template #current="{ label }">
      <CyTooltip
        :disabled="!ellipsisStatus[label]"
        bottom>
        <template #activator="{ on: onTooltip }">
          <span
            :ref="`breadcrumb-item-${label}`"
            data-cy="breadcrumb-current"
            class="d-block breadcrumb__link breadcrumb__link--current"
            v-on="onTooltip"
            @mouseover="setEllipsisStatus(label)">
            {{ label }}
          </span>
        </template>
        {{ label }}
      </CyTooltip>
    </template>
  </app-breadcrumbs>
</template>

<script>
export default {
  name: 'CyToolbarBreadcrumbs',
  data: () => ({
    ellipsisStatus: [],
  }),
  methods: {
    setEllipsisStatus (label) {
      const { offsetWidth, scrollWidth } = this.$refs[`breadcrumb-item-${label}`] || {}
      this.$set(this.ellipsisStatus, label, offsetWidth < scrollWidth)
    },
  },
}
</script>
