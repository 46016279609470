<template>
  <CyModal
    v-if="value"
    modal-type="create"
    data-cy="new-organization-modal"
    :header-title="$t('createOrg')"
    action-btn-color="secondary"
    :action-btn-text="$t('forms.btnCreate')"
    :action-btn-func="createOrg"
    :action-btn-disabled="$v.$invalid"
    :cancel-btn-func="emitCloseModal"
    :close-modal-on-action-click="false"
    :loading="loading">
    <v-text-field
      ref="nameField"
      v-model="$v.formContent.name.$model"
      :label="$t('forms.fieldOrgName')"
      :error-messages="nameErrors"
      required
      class="required-field"/>
    <div
      v-if="!_.isEmpty(organizations)"
      v-has-rights-to="'CreateChild'"
      class="d-flex">
      <v-checkbox
        v-model="isSubOrganization"
        color="secondary"
        :ripple="false"
        :label="$t('isSubOrganization')"/>
      <v-select
        v-model="$v.formContent.parentOrg.$model"
        class="ml-4"
        :items="organizations"
        :disabled="!isSubOrganization"
        item-text="name"
        item-value="canonical"
        return-object/>
    </div>
  </CyModal>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { pagination as createAPIPage } from '@/utils/api'
import { required, requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'CyFormsAddOrganization',
  validations: {
    formContent: {
      name: { required },
      parentOrg: {
        required: requiredIf(function () {
          return this.isSubOrganization
        }),
      },
    },
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isSubOrganization: false,
    formContent: {
      name: '',
      parentOrg: null,
    },
    loading: false,
  }),
  computed: {
    ...mapState({
      organizations: (state) => state.organizations,
    }),
    nameErrors () {
      const errors = []
      const { $dirty, required } = this.$v.formContent.name
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
  },
  methods: {
    ...mapActions('organization', [
      'CREATE_ORGANIZATION',
      'CHANGE_ORGANIZATION',
    ]),
    async createOrg () {
      this.$toggle.loading(true)
      const reqPage = createAPIPage(1, 100)
      const { $router, orgCanonical, formContent: { name } } = this
      const organization = { name, canonical: this.$getSlug(name) }
      if (!this.isSubOrganization) await this.CREATE_ORGANIZATION({ reqPage, $router, organization, parentCanonical: null })
      else {
        const { canonical: parentCanonical, name: parentName } = this.formContent.parentOrg
        orgCanonical !== parentCanonical
          ? await this.CHANGE_ORGANIZATION({ nextCanonical: parentCanonical, name: parentName })
          : await this.CREATE_ORGANIZATION({ reqPage, $router, organization, parentCanonical })
      }
      this.emitCloseModal()
      this.$toggle.loading(false)
    },
    emitCloseModal () {
      this.$emit('input', false)
    },
  },
  i18n: {
    messages: {
      en: {
        createOrg: 'Create a new organization',
        isSubOrganization: 'Sub-Organization of',
      },
      es: {
        createOrg: 'Crear una nueva organización',
        isSubOrganization: 'Suborganización de',
      },
      fr: {
        createOrg: 'Créer une nouvelle organisation',
        isSubOrganization: 'Sous-organisation de',
      },
    },
  },
}
</script>
