var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isAuthenticated)?_c('div',{class:['app-container', {
    'app-container--is-animating': _vm.isAnimating, // Prevents horizontal scrollbars while animating
  }],style:([{
    '--sidebar-width': _vm.sidebar.collapsed ? '72px' : '256px',
  }])},[_c('CyAlertModal'),_c('CyDevLayer'),_c('transition',{attrs:{"name":"slide-fade-left","appear":""}},[_c('CyMainNav')],1),_c('transition',{attrs:{"name":"slide-fade-right","appear":""},on:{"before-enter":function () { return _vm.$toggle.isAnimating(true); },"after-enter":function () { return _vm.$toggle.isAnimating(false); }}},[_c('v-main',{class:['main', {
        'main--fit-on-screen': _vm.fitOnScreen,
        'main--full-screen': _vm.isMobile || _vm.isFullScreen,
      }]},[(!_vm.isMobile && !_vm.isFullScreen)?_c('div',{staticClass:"sticky-background"}):_vm._e(),_c('CyToolbar'),_c(_vm.headerComponent,{directives:[{name:"show",rawName:"v-show",value:(!_vm.isFullScreen),expression:"!isFullScreen"}],tag:"component"}),_c('div',{class:[("content-wrapper content-wrapper--" + _vm.variant), {
          'content-wrapper--fit-on-screen': _vm.fitOnScreen,
          'content-wrapper--no-padding': _vm.noPadding,
        }]},[_c('div',{staticClass:"content"},[_vm._t("default")],2),_c('back-to-top',{attrs:{"bottom":"78px","right":"4px","visibleoffset":"800"}},[_c('CyButton',{attrs:{"icon-only":"","icon":"expand_less","theme":"primary"}})],1)],1),_c('CyFooter')],1)],1),_c('portal-target',{attrs:{"name":"side-panel"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }