<template>
  <div>
    <CyMenu
      bottom
      content-class="org-menu py-2"
      data-cy="organization-menu"
      left
      min-width="264"
      nudge-right="6"
      nudge-bottom="10"
      offset-y
      origin="top right"
      transition="slide-y-transition"
      @input="(isOpened) => searchTerm = (isOpened ? '' : searchTerm)">
      <template #activator="{ on, value: isMenuOpen }">
        <button
          v-ripple
          :class="['org-menu__activator', {
            'org-menu__activator--active': isMenuOpen,
          }]"
          data-cy="organization-menu-activator"
          v-on="on">
          <CyAvatar
            class="org-menu__avatar mr-2"
            :item="currentOrganization"/>
          <span
            class="org-menu__text mr-1"
            data-cy="organization-name">
            {{ currentOrganization.name }}
          </span>
          <v-icon class="mr-n1">
            unfold_more
          </v-icon>
        </button>
      </template>

      <v-subheader @click.stop="">
        <CySearchBox
          v-model.trim="searchTerm"
          append-icon="search"
          autofocus
          data-cy="search-box"
          :placeholder="$t('searchOrganizations')"/>
      </v-subheader>

      <v-list
        v-if="!_.isEmpty(filteredOrganizations)"
        dense
        nav
        data-cy="organizations-list"
        class="org-menu__organizations">
        <v-hover
          v-for="organization in filteredOrganizations"
          #default="{ hover: isHovering }"
          :key="organization.name">
          <v-list-item
            v-hover-siblings
            data-cy="organizations-list-item"
            @click="changeOrganization(organization.canonical)">
            <v-list-item-action>
              <CyAvatar
                :item="organization"
                sm/>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>
                {{ organization.name }}

                <v-chip
                  v-if="isRootOrg(organization)"
                  x-small
                  class="org-menu__root-chip ml-2">
                  {{ $t('untranslated.root') }}
                </v-chip>
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action
              class="org-menu__subtitle mr-0">
              <span v-if="!isOrganizationSelected(organization) && isHovering">
                {{ $t('switch') }}
              </span>

              <v-icon
                v-if="isOrganizationSelected(organization)"
                size="18">
                check
              </v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-hover>
      </v-list>

      <div
        v-else
        data-cy="no-organizations-found"
        class="org-menu--empty px-4 py-2">
        <h4 v-text="$t('noOrganizationsFound.title')"/>
        <div v-text="$t('noOrganizationsFound.text')"/>
      </div>

      <v-divider class="my-2"/>

      <v-list
        nav
        dense
        class="org-menu__actions">
        <v-list-item
          v-hover-siblings
          data-cy="see-all-organizations"
          @click="goToOrganizationsList">
          <v-list-item-action>
            <v-icon>
              vertical_split
            </v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>
              {{ $t('seeAllOrganizations') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-hover-siblings
          data-cy="new-organization"
          @click="openModal">
          <v-list-item-action>
            <v-icon>
              add
            </v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>
              {{ $t('routes.marketplaceNewOrganization') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-hover-siblings
          data-cy="organization-settings"
          @click="goToOrganizationSettings">
          <v-list-item-action>
            <v-icon>
              settings
            </v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>
              {{ $t('organizationSettings') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </CyMenu>
    <CyFormsAddOrganization
      v-model="isModalVisible"/>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import CyFormsAddOrganization from '@/components/forms/add-organization.vue'
import CySearchBox from '@/components/search-box.vue'
import { gtmBreadcrumbsEvents } from '@/utils/helpers/analytics'

const VISIBLE_ORGANIZATIONS = 5

export default {
  name: 'CyToolbarOrgMenu',
  components: {
    CyFormsAddOrganization,
    CySearchBox,
  },
  data () {
    return {
      isModalVisible: false,
      searchTerm: '',
    }
  },
  computed: {
    ...mapState({
      organizations: (state) => state.organizations,
    }),
    ...mapGetters('organization', {
      currentOrganization: 'organization',
    }),
    $static: () => ({
      gtmBreadcrumbsEvents,
    }),
    filteredOrganizations () {
      return this.organizations
        .filter(({ name }) => name.toLowerCase().includes(this.searchTerm.toLowerCase()))
        .slice(0, VISIBLE_ORGANIZATIONS)
    },
  },
  created () {
    if (_.isEmpty(this.organizations)) {
      this.FETCH_ORGS()
    }
  },
  methods: {
    ...mapActions([
      'FETCH_ORGS',
    ]),
    ...mapActions('organization', [
      'CHANGE_ORGANIZATION',
    ]),
    isOrganizationSelected (organization) {
      return organization.name === this.currentOrganization.name
    },
    goToOrganizationsList () {
      this.$gtm.trackEvent(this.$static.gtmBreadcrumbsEvents.breadcrumbsOrganizationsSeeAllOrganizations)
      this.$router.push({ name: 'organizations' }).catch(() => { /* silenced */ })
    },
    async goToOrganizationSettings () {
      const params = { orgCanonical: this.currentOrganization.canonical }
      this.$router.push({ name: 'orgSettings', params }).catch(() => { /* silenced */ })
    },
    goToOrganization () {
      const { orgCanonical } = this
      this.$route.name === 'dashboard'
        ? this.$router.replace({ name: 'dashboard', params: { orgCanonical } }).catch(() => { /* silenced */ })
        : this.$router.push({ name: 'dashboard', params: { orgCanonical } })
    },
    isRootOrg ({ canonical }) {
      return canonical === this.organizations[0].canonical
    },
    openModal () {
      this.isModalVisible = true
    },
    async changeOrganization (orgCanonical) {
      this.$gtm.trackEvent(this.$static.gtmBreadcrumbsEvents.breadcrumbsOrganizationsSwitch)
      const orgChangeSuccess = await this.CHANGE_ORGANIZATION({ nextCanonical: orgCanonical })
      if (orgChangeSuccess) this.goToOrganization()
    },
  },
  i18n: {
    messages: {
      en: {
        seeAllOrganizations: 'See all organizations',
        noOrganizationsFound: {
          title: 'No organizations found',
          text: 'Note that you can only search for organizations of which you are a member.',
        },
        organizationSettings: 'Organization settings',
        searchOrganizations: 'Search organizations',
        switch: 'switch',
      },
      es: {
        seeAllOrganizations: 'Ver todas las organizaciones',
        noOrganizationsFound: {
          title: 'No se encontraron organizaciones',
          text: 'Tenga en cuenta que solo puede buscar organizaciones de las que sea miembro.',
        },
        organizationSettings: 'Configuración de la organización',
        searchOrganizations: 'Buscar organizaciones',
        switch: 'cambiar',
      },
      fr: {
        seeAllOrganizations: 'Voir toutes les organisations',
        noOrganizationsFound: {
          title: 'Aucune organisation trouvée',
          text: 'Notez que vous ne pouvez rechercher que les organisations dont vous êtes membre.',
        },
        organizationSettings: 'Configuration de l`organisation',
        searchOrganizations: 'Rechercher dans les organisations',
        switch: 'changer',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.org-menu {
  &__actions {
    .v-icon {
      color: inherit;
    }
  }

  &__activator {
    display: flex;
    align-items: center;
    padding: 8px;
    transition: background 0.25s ease-in;
    border-radius: 2px;
    cursor: pointer;

    &--active,
    &:hover,
    &:focus-visible {
      background-color: cy-get-color("primary", "light-4");

      .v-icon {
        color: cy-get-color("primary", "light-2");
      }
    }

    &--with-border {
      border: dashed 1px cy-get-color("primary", "light-1");
    }

    .v-icon { /* stylelint-disable-line no-descending-specificity */
      color: cy-get-color("primary", "light-3");
    }
  }

  &__avatar.cy-avatar {
    width: 24px;
    min-width: auto;
    height: 24px;

    ::v-deep .cy-avatar__initials {
      font-size: 12px;
    }
  }

  &__root-chip {
    margin-bottom: 2px;
    border-radius: 3px;
  }

  &__subtitle {
    color: cy-get-color("primary", "light-2");
    font-size: $font-size-xs;
    font-style: italic;
    font-weight: $font-weight-default;
  }

  &__text {
    font-weight: $font-weight-bolder;
    line-height: 1;
  }

  &--empty {
    max-width: 264px;
  }

  .v-list-item {
    height: 40px;
    padding-right: 8px;
    padding-left: 8px;
    color: cy-get-color("primary", "main") !important;
    cursor: pointer;
  }
}
</style>
