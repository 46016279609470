<template>
  <v-navigation-drawer
    :class="['cy-sidebar', {
      'cy-sidebar--hidden': !isSidebarVisible,
      'cy-sidebar--collapsed': isSidebarCollapsed,
      'cy-sidebar--mobile': isMobile,
    }]"
    :app="!isOverlapping"
    :mobile-breakpoint="600"
    :disable-resize-watcher="false"
    disable-route-watcher
    :mini-variant="isSidebarCollapsed"
    :mini-variant-width="72"
    :value="isSidebarVisible"
    :width="isMobile ? '100%' : '256'"
    :dark="isDark"
    fixed
    floating
    permanent>
    <div
      :class="['main-nav', { 'main-nav--collapsed': isSidebarCollapsed }]">
      <div class="main-nav__header">
        <CyCompanyLogo data-cy="company-logo"/>

        <button
          v-if="isMobile"
          class="main-nav__item ml-auto"
          data-cy="nav-show-hide"
          @click="TOGGLE_SIDEBAR_VISIBILITY">
          <v-icon>close</v-icon>
        </button>
      </div>

      <v-divider class="mb-4 mx-4"/>

      <div class="main-nav__scroll-container">
        <transition name="slide-fade-top">
          <div
            v-show="isPrevIconVisible"
            class="main-nav__scroll-container__icon main-nav__scroll-container__icon--prev">
            <v-icon>$vuetify.icons.doubleArrowUp</v-icon>
          </div>
        </transition>

        <v-list
          v-if="!!orgCanonical"
          ref="list"
          v-resize-observer="onListResize"
          :class="['main-nav__list main-nav__list--scrollable', {
            'main-nav__list--disabled': !hasActiveBillingPlan,
          }]"
          @scroll.native="onListResize">
          <div
            v-for="({ action, name, routeName, icon, params, children, isActive }, index) in menuItems"
            :key="`item-${name}-${isSidebarCollapsed}`"
            class="main-nav__menu-item">
            <CyTooltip
              :disabled="!isSidebarCollapsed"
              right>
              <template #activator="{ on }">
                <!-- expandable nav item -->
                <v-list-group
                  v-if="!isSidebarCollapsed && children"
                  :value="isActive"
                  :disabled="!hasActiveBillingPlan || isActive">
                  <template #activator>
                    <router-link
                      :class="['main-nav__list-item-link',
                               { 'main-nav__list-item-link--deep-child-active' : isActive && isOnDeeplyNestedRoute(children) }]"
                      :to="{ name, params }"
                      custom>
                      <v-list-item-icon>
                        <v-icon>{{ icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $t(`routes.${routeName}`) }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </router-link>
                  </template>

                  <v-list-item
                    v-for="{ name: childName, params: childParams } in children"
                    :key="`item-${name}-${childName}`"
                    :disabled="!hasActiveBillingPlan">
                    <router-link
                      class="main-nav__list-item-link"
                      :to="{ name: childName, params: childParams }">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $t(`routes.${childName}`) }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </router-link>
                  </v-list-item>
                </v-list-group>

                <!-- non-expandable nav item -->
                <v-list-item
                  v-else
                  :data-cy="`nav-${name}`"
                  :disabled="!hasActiveBillingPlan"
                  v-on="on"
                  @click.native="action">
                  <router-link
                    class="main-nav__list-item-link"
                    :to="{ name, params }">
                    <v-list-item-icon>
                      <v-icon>{{ icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t(`routes.${name}`) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </router-link>
                </v-list-item>
              </template>
              {{ $t(`routes.${name}`) }}
            </CyTooltip>

            <CyProductTourStep
              v-if="!isMobile"
              v-bind="{ index, name }"
              :total-step-count="menuItems.length"/>
          </div>
        </v-list>

        <transition name="slide-fade-bottom">
          <div
            v-show="isNextIconVisible"
            class="main-nav__scroll-container__icon main-nav__scroll-container__icon--next">
            <v-icon>$vuetify.icons.doubleArrowDown</v-icon>
          </div>
        </transition>
      </div>

      <v-divider class="mt-auto ma-4"/>

      <v-list
        v-if="$showDevThings"
        :class="['main-nav__list mb-4 d-flex flex-column align-start dev-list', {
          'main-nav__list--collapsed': isSidebarCollapsed,
        }]">
        <CyDevLocaleSwitcher v-bind="{ isSidebarCollapsed }"/>
        <CyDevLayerActivatorBtn v-bind="{ isSidebarCollapsed }"/>
      </v-list>

      <button
        v-if="!isMobile"
        slot="activator"
        :class="['main-nav__item main-nav__item--toggler', {
          'main-nav__item--toggler-rotate ml-auto mr-3': !isSidebarCollapsed,
        }]"
        @click="toggleSidebarMode">
        <v-icon>keyboard_arrow_right</v-icon>
      </button>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import CyCompanyLogo from '@/components/company-logo.vue'
import CyDevLayerActivatorBtn from '@/components/dev/layer-activator-btn.vue'
import CyDevLocaleSwitcher from '@/components/dev/locale-switcher.vue'
import CyProductTourStep from '@/components/product-tour-step.vue'
import {
  gtmCollapseEvents,
  gtmObservabilityEvents,
  gtmProjectsEvents,
  gtmSecurityEvents,
  gtmStacksEvents,
} from '@/utils/helpers/analytics'

export default {
  name: 'CyMainNav',
  components: {
    CyCompanyLogo,
    CyDevLocaleSwitcher,
    CyDevLayerActivatorBtn,
    CyProductTourStep,
  },
  data: () => ({
    list: {
      scrollHeight: 0,
      clientHeight: 0,
      scrollTop: 0,
    },
  }),
  computed: {
    ...mapState({
      isSidebarVisible: (state) => state.layout.sidebar.visible,
      prefersSidebarCollapsed: (state) => state.layout.sidebar.collapsed,
    }),
    ...mapGetters([
      'appearance',
      'isOrgSelected',
    ]),
    ...mapGetters('organization/billing', [
      'hasActiveBillingPlan',
    ]),
    sections () {
      if (!this.isOrgSelected) return []
      return [
        {
          name: 'dashboard',
          icon: 'dashboard',
        },
        {
          action: () => this.$gtm.trackEvent(gtmProjectsEvents.projects),
          name: 'projectsSection',
          icon: 'folder',
        },
        {
          action: () => this.$gtm.trackEvent(gtmStacksEvents.stacks),
          name: 'stacksSection',
          icon: 'fa-cubes',
        },
        {
          name: 'resourcesSection',
          icon: 'dns',
        },
        {
          action: () => this.$gtm.trackEvent(gtmObservabilityEvents.observability),
          name: 'observabilitySection',
          icon: 'explore',
        },
        {
          name: 'cloudCostManagementSection',
          routeName: 'cloudCostManagement',
          icon: 'attach_money',
        },
        {
          action: () => this.$gtm.trackEvent(gtmSecurityEvents.security),
          name: 'securitySection',
          icon: 'security',
        },
      ]
    },
    isSidebarCollapsed () {
      return this.prefersSidebarCollapsed && !this.isMobile
    },
    isMobile () {
      return this.$vuetify.breakpoint.xs
    },
    isOverlapping () {
      return this.isMobile && !this.isSidebarCollapsed && this.isSidebarVisible
    },
    isNextIconVisible () {
      return this.isListScrollable && (this.list.scrollHeight - this.list.clientHeight) > this.list.scrollTop
    },
    menuItems () {
      const { orgCanonical, sections } = this
      const params = { orgCanonical }

      if (!orgCanonical) return []

      return sections.reduce((accumulator, section) => {
        const { route } = this.$router.resolve({ name: section.name, params })
        const tabs = _.get(route, 'meta.tabs', () => [])()
        const authorizedTabs = tabs.reduce((acc, tab) => {
          if (this.$cycloid.permissions.canDisplayRoute({ name: tab, params })) acc.push({ name: tab, params })
          return acc
        }, [])

        if (!tabs.length || authorizedTabs.length) {
          const { action = () => { /* noop */ } } = section
          accumulator.push({
            ...section,
            action,
            routeName: _.get(section, 'routeName', section.name),
            params,
            isActive: !!this.$route.path.match(route.path),
            ...(authorizedTabs.length ? { children: authorizedTabs } : {}),
          })
        }
        return accumulator
      }, [])
    },
    isListScrollable () {
      return this.list.scrollHeight > this.list.clientHeight
    },
    isPrevIconVisible () {
      return this.list.scrollTop > 0
    },
    isDark () {
      return this.appearance?.color?.isDark ?? true
    },
  },
  watch: {
    isMobile: {
      handler (isMobile) {
        if (isMobile || !this.isSidebarVisible) this.TOGGLE_SIDEBAR_VISIBILITY()
      },
      immediate: true,
    },
    $route: {
      handler () {
        if (this.isMobile && this.isSidebarVisible) this.TOGGLE_SIDEBAR_VISIBILITY()
      },
      deep: true,
    },
    async orgCanonical () {
      await this.evaluatePermissions()
    },
  },
  async created () {
    await this.evaluatePermissions()
  },
  methods: {
    ...mapMutations('layout', [
      'TOGGLE_SIDEBAR_VISIBILITY',
      'TOGGLE_SIDEBAR_MODE',
    ]),
    isOnDeeplyNestedRoute (children) {
      return !(children.some((child) => child.name === this.$route.name))
    },
    async evaluatePermissions () {
      const { orgCanonical, sections } = this
      const params = { orgCanonical }

      if (!orgCanonical) return []

      const menuItemsActions = sections.reduce((accumulator, { name }) => {
        const location = { name, params }
        const { route } = this.$router.resolve(location)
        const requiredActions = this.$cycloid.permissions.getRouteRequiredActions(location)
        const tabs = _.get(route, 'meta.tabs', () => [])()

        const tabsRequiredActions = tabs.reduce((acc, tab) => {
          return [...acc, ...this.$cycloid.permissions.getRouteRequiredActions({ name: tab, params })]
        }, [])

        return [...accumulator, ...requiredActions, ...tabsRequiredActions]
      }, [])

      await this.$evaluateUserActions(_.uniq([
        ...menuItemsActions,
        'GetChildren',
        'GetOrg',
      ]))
    },
    onListResize: _.throttle(function () {
      const el = _.$get(this.$refs, 'list.$el')
      if (!el) return
      this.list.scrollHeight = el.scrollHeight
      this.list.clientHeight = el.clientHeight
      this.list.scrollTop = el.scrollTop
    }, 300),
    toggleSidebarMode () {
      const collapseEvent = this.prefersSidebarCollapsed ? 'mainMenuExpand' : 'mainMenuCollapse'
      this.$gtm.trackEvent(gtmCollapseEvents[collapseEvent])
      this.TOGGLE_SIDEBAR_MODE()
    },
  },
}
</script>

<style lang="scss" scoped>
$sidebar-text-color: var(--appearance-text-color);
$sidebar-text-alpha-10: rgb(var(--appearance-text-color-values) / 0.1);
$sidebar-text-alpha-15: rgb(var(--appearance-text-color-values) / 0.15);
$sidebar-text-alpha-20: rgb(var(--appearance-text-color-values) / 0.2);
$sidebar-text-alpha-25: rgb(var(--appearance-text-color-values) / 0.25);
$sidebar-text-alpha-75: rgb(var(--appearance-text-color-values) / 0.75);

.cy-sidebar {
  flex-shrink: 0;
  overflow: visible;
  background: var(--appearance-bg-color);

  &--hidden {
    width: 0 !important;
  }

  &--mobile {
    z-index: 300;
  }
}

.main-nav {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 0 0 16px;
  overflow: hidden;

  &__header {
    display: flex;
    align-items: center;
  }

  ::v-deep .company-logo {
    display: flex;
    align-items: center;
    overflow: hidden;
    outline: none;

    .cy-app-logo {
      flex: 1 0 auto;
    }

    &__name {
      height: 40px;
      margin-left: 12px;
      color: $sidebar-text-color;
      font-size: 24px;
      font-weight: $font-weight-bolder;
      line-height: 40px;
    }

    &--disabled {
      cursor: default;
      pointer-events: none;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    background: none;

    > * + * {
      margin-top: 8px;
    }

    &.dev-list {
      padding: 0 14px;
    }

    &--scrollable {
      max-height: 100%;
      overflow-y: auto;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        width: 0;
        background-color: transparent;
      }
    }

    &--disabled {
      background: none;
      color: $sidebar-text-alpha-25;
      pointer-events: none;

      .v-list-item {
        pointer-events: none;
      }

      a.main-nav__list-item-link {
        background: none;
        color: $sidebar-text-alpha-25;
      }

      .v-icon {
        color: $sidebar-text-alpha-25 !important;
      }
    }
  }

  &__scroll-container {
    position: relative;
    min-height: 0;

    &__icon {
      display: flex;
      position: absolute;
      z-index: 1;
      left: calc(50% - 16px);
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 8px;
      color: $sidebar-text-color;
      cursor: default;
      user-select: none;

      &--prev {
        top: 0;
        background: cy-get-color("secondary", "dark-1");
        box-shadow: 0 0 0 1px cy-get-color("secondary", "dark-1");
      }

      &--next {
        bottom: 0;
        background: cy-get-color("primary", "main");
        box-shadow: 0 0 0 1px cy-get-color("primary", "main");
      }
    }
  }

  &__item--toggler {
    padding: 8px;
    border-radius: 8px;

    &:hover,
    &:focus-visible {
      background-color: $sidebar-text-alpha-10;
    }

    .v-icon {
      transition: transform 0.2s ease-out;
    }

    &-rotate .v-icon {
      transform: rotate3d(0, 1, 0, 180deg);
    }
  }

  ::v-deep .v-list-item {
    min-height: unset;
    padding: 0;
    overflow: hidden;
    border-radius: 8px;
    color: $sidebar-text-alpha-75;

    &::after {
      display: none;
    }

    &:focus,
    &:hover {
      outline: none;
      background-color: transparent;
    }

    a:visited {
      color: $sidebar-text-alpha-75;
    }

    .v-list-item__icon {
      margin: 0;
    }

    .v-icon {
      color: $sidebar-text-alpha-75;
    }

    .v-list-item__content {
      padding: 0;
    }

    .v-list-item__title {
      margin-left: 12px;
      font-size: $font-size-default !important;
      font-weight: $font-weight-default;
      white-space: nowrap;
    }
  }

  &__list-item-link {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 40px;
    padding: 8px;
    transition: background-color 0.2s ease-out;
    border-radius: 8px;
    color: $sidebar-text-color;

    &.router-link-exact-active {
      background-color: $sidebar-text-alpha-15;

      .v-list-item__icon .v-icon {
        color: $sidebar-text-color;
      }
    }

    &:active,
    &:focus {
      color: $sidebar-text-color;
    }

    &:focus-visible,
    &:hover {
      outline: none;
      background-color: $sidebar-text-alpha-10;
      color: $sidebar-text-color;

      .v-list-item__icon .v-icon {
        color: $sidebar-text-color;
      }
    }

    &--deep-child-active {
      background-color: $sidebar-text-alpha-15;
    }
  }

  ::v-deep .v-list-group {
    .v-list-group__header__append-icon {
      display: none;
      min-width: 0;
    }

    .v-list-group__items {
      .v-list-item:first-child {
        margin-top: 8px;
      }

      .main-nav__list-item-link {
        padding-left: 32px;

        &.router-link-active {
          background-color: $sidebar-text-alpha-15;
        }
      }
    }
  }

  &--collapsed {
    .main-nav__item--toggler {
      margin-right: auto;
      margin-left: auto;
    }

    .main-nav__list {
      .v-list-item .v-list-item__content {
        display: none;
      }

      .router-link-active {
        background-color: $sidebar-text-alpha-15;

        .v-icon {
          color: $sidebar-text-color;
        }
      }
    }
  }
}

.slide-fade-top {
  &-enter-active,
  &-leave-active {
    transition:
      transform 0.2s ease,
      opacity 0.2s ease;
  }

  &-enter,
  &-leave-to {
    transform: translateY(-8px) !important;
    opacity: 0;
  }
}

.slide-fade-bottom {
  &-enter-active,
  &-leave-active {
    transition:
      transform 0.2s ease,
      opacity 0.2s ease;
  }

  &-enter,
  &-leave-to {
    transform: translateY(8px) !important;
    opacity: 0;
  }
}
</style>
