<template>
  <v-md-editor
    v-if="!$route.meta.layout.hideFooter && !_.isEmpty(footer)"
    v-model="footer"
    class="custom-footer text-center"
    mode="preview"
    height="auto"/>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CyFooter',
  computed: {
    ...mapState({
      footer: (state) => state.organization.detail.appearance.footer,
    }),
  },
}
</script>

<style lang="scss" scoped>
.v-md-editor {
  &--preview {
    background-color: transparent;
  }

  ::v-deep {
    .github-markdown-body {
      padding: 16px;

      p {
        margin-bottom: 0;
        font-size: $font-size-default;
      }
    }
  }
}
</style>
