<template>
  <CyMenu
    left
    bottom
    offset-y
    transition="slide-y-transition"
    origin="top right"
    content-class="mt-2 py-2 cy-menu secondary-nav__menu profile-menu no-active-indicators"
    min-width="256"
    nudge-right="6"
    role="user-menu">
    <template #activator="{ on }">
      <button
        v-ripple
        class="user-menu-activator"
        data-cy="user-menu-activator"
        v-on="on">
        <CyAvatar
          sm
          :item="profile"/>
      </button>
    </template>

    <v-list
      dense
      class="user-menu py-0"
      data-cy="user-menu">
      <div
        v-if="profile"
        class="user-summary px-4 py-2">
        <CyAvatar
          class="flex-shrink-0 mr-2"
          :item="profile"
          data-cy="user-avatar"/>
        <div class="user-summary__body">
          <div
            class="user-summary__title"
            data-cy="user-display-name">
            {{ displayName(profile) }}
          </div>
          <div
            v-if="userOrganizationRole"
            class="user-summary__caption">
            {{ userOrganizationRole }}
          </div>
        </div>
      </div>

      <v-divider class="my-2"/>

      <v-list-item
        v-hover-siblings
        class="cy-menu__item px-4"
        data-cy="user-profile-btn"
        :to="{ name: 'profile' }">
        <v-list-item-title>
          <v-icon color="primary">
            account_box
          </v-icon>
          <span>{{ $t('routes.profile') }}</span>
        </v-list-item-title>
      </v-list-item>

      <v-list-item
        v-hover-siblings
        class="cy-menu__item px-4"
        data-cy="logout-btn"
        :to="{ name: 'logout', ...(orgCanonical ? { params: { orgCanonical } } : {}) }">
        <v-list-item-title>
          <v-icon color="primary">
            exit_to_app
          </v-icon>
          <span>{{ $t('logout') }}</span>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </CyMenu>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { displayName } from '@/utils/helpers'

export default {
  name: 'CySecondaryNavUserMenu',
  computed: {
    ...mapState({
      profile: (state) => state.user.profile,
    }),
    ...mapGetters('auth', [
      'jwtDecoded',
    ]),
    userOrganizationRole () {
      return _.get(this.jwtDecoded, 'role', false)
    },
  },
  methods: {
    displayName,
  },
  i18n: {
    messages: {
      en: {
        logout: 'Log out',
      },
      es: {
        logout: 'Cerrar sesión',
      },
      fr: {
        logout: 'Déconnexion',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.user-menu-activator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $spacer-8;
  height: $spacer-8;
  border-radius: $spacer-8;

  &:hover {
    background-color: cy-get-color("primary", "light-3", 0.55);
  }

  &:active,
  &:focus {
    background-color: cy-get-color("primary", "light-2", 0.6);
  }
}

.user-menu {
  width: 256px;
}

.v-list-item {
  min-height: 40px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  &__title {
    display: flex;
    align-items: center;

    .v-icon {
      margin-right: $spacer-2;
    }
  }

  &--active {
    .v-list-item__title {
      font-weight: $font-weight-bolder;
    }
  }
}

.user-summary {
  display: flex;
  align-items: center;

  &__body {
    min-width: 0;
    line-height: 15px;
  }

  &__title,
  &__caption {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__caption {
    margin-top: 2px;
    color: cy-get-color("primary", "light-2");
    font-size: $font-size-sm;
  }
}
</style>
